<template>
  <div>
    <loading :active.sync="isLoading" :enforce-focus="false"></loading>
    <!-- 複数選択 モーダル -->
    <b-modal :id="modal_id" title="一括" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered>
      <div class="main_modal_div">
        <div style="margin-bottom: 5px;" class="form-inline">
          <b-form-select v-model="on_type2" style="width: 100px" @change="onDateChange">
            <option v-for="option in sel_on_type" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          <v-date-picker v-model="sel_date1" @input="onDateChange" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
          &nbsp;-&nbsp;
          <v-date-picker v-model="sel_date2" @input="onDateChange" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
        </div>
        <div style="width: 1200px; ">
          <b-table-filter-pager :busy="mitumori_sel_load" :maxHeight="maxHeight2" :filter_list="sel_filter_list"
            :filter_val="sel_filter_val" :filter_val_ex="sel_filter_val_ex" :data_count="sel_data_count"
            :data_list_field="sel_data_list_field" :data_list="sel_data_list" :all_check_sts="all_check_sts"
            :currentPage="current_page" @on_sort="onSort" @jyutyu_show="jyutyuShow" @nouhin_show="nouhinShow"
            @seikyu_show="seikyuShow" @nyukin_show="nyukinShow" @on_all_check="onAllCheck"
            @on_page_change='onSelPageChange' @on_filter_ex='onSelFilterEx' @on_mitumori_sel="onSelect" :nav_flg="true"
            :filter_enable="true" :add_btn="false" />
        </div>
      </div>

      <div class="form-inline" style="margin-top: 20px;">
        <div style="width: 120px; background-color: #eeeded;">
          選択数：
          <span style="float: right;">&nbsp;&nbsp;{{ sel_id.length }}件</span>
        </div>
        &nbsp;&nbsp;
        <b-form-select v-model="on_submit" style="width: 200px" @change="selSubmit">
          <option disabled value="-1">処理を選択</option>
          <option v-for="option in sel_on_submit" v-bind:value="option.value" :key="option.id">
            {{ option.text }}
          </option>
        </b-form-select>
        &nbsp;&nbsp;
        <b-button v-if="!print_loading" @click="printNouhinSeikyu" variant="success" style="width:160px !important">
          <b-icon icon="printer"></b-icon>&nbsp;納品・請求書
        </b-button>
        <b-button v-if="print_loading" variant="success" disabled style="width:160px !important;">
          <b-spinner small></b-spinner>
          Loading...
        </b-button>
        &nbsp;&nbsp;
        <b-form-checkbox id="checkbox-flg1" v-model="nouhin_on_print_flg" name="checkbox-flg1">
          納品日印刷
        </b-form-checkbox>
        <b-form-checkbox id="checkbox-flg2" v-model="seikyu_on_print_flg" name="checkbox-flg2">
          請求日印刷
        </b-form-checkbox>
      </div>



      <!-- 置換モーダル -->
      <b-modal id="modal-mitumori-tikan" :title="tikan_title" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered @hide="closeTikanModal">
        <div class="modal_div">
          <div v-for="(tikan_name, index) in tikan_name_list" :key="tikan_name" class="form-inline">
            <label :for="'tikan_chk' + index">{{ tikan_name }}</label>
            <input type="checkbox" :id="'tikan_chk' + index" v-model="tikan_chk[index]">
            <span v-if="index < 3">
              <b-input v-model="tikan[index]" type="text" style="width: 200px" />
            </span>
            <span v-else-if="index == 3">
              <SelectClientCompany ref="select_client_company_ref" @on_select='onTikanClientCompanySelect' />
              <b-input v-model="tikan_client_company_name" type="text" style="width:200px; background-color: white;"
                @click="selectClientCompanyModalShow" readonly />
              <b-button v-if="!client_loading" @click="selectClientCompanyModalShow" variant="info" class="icon_btn">
                <b-icon icon="search" aria-hidden="true"></b-icon>
              </b-button>
              <b-button v-if="client_loading" variant="info" class="icon_btn" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </b-button>
            </span>
            <span v-else-if="index >= 4">
              <v-date-picker v-model="tikan[index]" :model-config="modelConfig" class="date_picker">
                <template v-slot="{ inputValue, inputEvents }">
                  <b-input :value="inputValue" v-on="inputEvents" readonly />
                </template>
              </v-date-picker>
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <b-button @click="$bvModal.hide('modal-mitumori-tikan')">
            <b-icon icon="x"></b-icon>取消
          </b-button>
          <b-button v-if="tikan_type == 0" variant="primary" @click="submitCopy">
            <b-icon icon="files"></b-icon>&nbsp;複製
          </b-button>
          <b-button v-if="tikan_type == 1" variant="primary" @click="submitTikan">
            <b-icon icon="arrow-left-right"></b-icon>&nbsp;置換
          </b-button>
        </div>
      </b-modal>
    </b-modal>
    <!-- 受注 詳細 -->
    <JyutyuDetail ref="child_jyutyu_detail2" :modal_id="'main-jyutyu_detail2'" @close_modal='closeModal2' />
    <!-- 納品 詳細 -->
    <NouhinDetail ref="child_nouhin_detail2" :modal_id="'main-nouhin_detail2'" @close_modal='closeModal2' />
    <!-- 請求 詳細 -->
    <SeikyuDetail ref="child_seikyu_detail2" :modal_id="'main-seikyu_detail2'" @close_modal='closeModal2' />
    <!-- 入金 -->
    <NyukinDetail ref="child_nyukin_detail2" :modal_id="'main-nyukin_detail2'" @close_modal='closeModal2' />

  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import VCalendar from 'v-calendar'
Vue.use(VCalendar)

const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  data: function () {
    return {
      isLoading: false,
      mitumori_sel_load: false,
      sel_on_type: [{ value: 0, text: "見積日" }, { value: 1, text: "受注日" },
      { value: 2, text: "納品日" }, { value: 3, text: "請求日" }],
      filter_val_ex: new Array(15),
      // datepickerでdefaultが空の場合、時刻がおかしくなる(Timezone)ので日付けのみに変換
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      // 複数選択
      current_page: 1,
      busy: false,
      sort_by: '',
      sort_desc: true,
      print_loading: false,
      nouhin_on_print_flg: true,
      seikyu_on_print_flg: true,
      on_type2: 0,
      sel_date1: '',
      sel_date2: '',
      sel_id: [],
      submit_sel_id: [],
      sel_filter_list: new Array(15),
      sel_filter_val: new Array(15),
      sel_filter_val_ex: new Array(15),
      sel_data_count: 0,
      sel_data_list: [],
      all_check_sts: false,
      all_check_flg: false,
      sel_data_list_field: [
        { key: "mitumori_sel", label: "選択", thStyle: { width: "60px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: "all_check" },
        { key: "jyutyu_status", label: "受注", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_sts_ex' },
        { key: "nouhin_status", label: "納品", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_sts_ex' },
        { key: "seikyu_status", label: "請求", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_sts_ex' },
        { key: "nyukin_status", label: "入金", thStyle: { width: "70px", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: 'select_sts_ex' },
        { key: "m_code", label: "伝票番号", thStyle: { width: "100px" }, sortable: true, filter: 'input_ex' },
        { key: "title", label: "品題", thStyle: { width: "180px" }, sortable: true, filter: 'input_ex' },
        { key: "teiki_type1", label: "品類", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "teiki_type2", label: "品目", thStyle: { width: "120px" }, sortable: true, filter: 'input_ex' },
        { key: "mitumori_on", label: "見積日", thStyle: { width: "140px" }, sortable: true, filter: 'input_ex' },
        { key: "client_company_name", label: "顧客", thStyle: {}, sortable: true, filter: 'input_ex' },
      ],
      // 処理
      on_submit: -1,
      sel_on_submit: [{ value: 0, text: "複製" }, { value: 1, text: "置換" },
      { value: 2, text: "受注確定" }, { value: 3, text: "納品済み" },
      { value: 4, text: "請求済み" }, { value: 5, text: "納品・請求済み" }],
      // 置換
      tikan_title: '複製',
      tikan_type: 0,
      tikan_name_list: ["品題", "品類", "品目", "顧客", "見積日", "納品日", "請求日"],
      tikan_chk: [false, false, false, false, false, false, false],
      tikan: ["", "", "", 0, "", "", ""],
      tikan_client_company_name: "",
      //
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight2: document.documentElement.scrollHeight - 360 + "px"
    }
  },
  mounted() {
  },
  methods: {
    // =======================================================
    // 顧客選択モーダル表示
    selectClientCompanyModalShow: function () {
      this.client_loading = true;
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'select-client-company');
        this.client_loading = false;
      }, 50);
    },
    // 顧客選択
    onClientCompanySelect: function (item_id) {
      axios
        .get(`/mitumori/get_sel_client_person/${item_id}`)
        .then(response => {
          this.sel_client_person = response.data.sel_client_person;
          this.mitumori.client_company_id = response.data.client_company_id;
          this.mitumori.client_company_name = response.data.client_company_name;
          this.mitumori.client_person_id = "";
          this.$root.$emit('bv::hide::modal', 'select-client-company')
        })
    },
    // =======================================================
    // 複数選択
    onSelPageChange: function (current_page) {
      this.all_check_sts = false
      this.current_page = current_page;
      this.getSelectList();
    },
    //
    onDateChange: function () {
      this.sel_id = []
      this.current_page = 1;
      this.all_check_flg = false;
      this.all_check_sts = false;
      this.getSelectList();
    },
    //
    onSelFilterEx: function () {
      this.sel_id = []
      this.current_page = 1;
      this.all_check_flg = false;
      this.all_check_sts = false;
      this.getSelectList();
    },
    // 複数選択 一覧取得
    getSelectList: function () {
      this.isLoading = true;
      this.sel_id = []
      axios
        .get('/mitumori/get_mitumori_list', {
          params: {
            on_type: this.on_type2, date1: this.sel_date1, date2: this.sel_date2,
            jyutyu_sts_ex: this.sel_filter_val_ex[1], nouhin_sts_ex: this.sel_filter_val_ex[2],
            seikyu_sts_ex: this.sel_filter_val_ex[3], nyukin_sts_ex: this.sel_filter_val_ex[4],
            m_code_ex: this.sel_filter_val_ex[5], title_ex: this.sel_filter_val_ex[6],
            teiki_type1_ex: this.sel_filter_val_ex[7], teiki_type2_ex: this.sel_filter_val_ex[8],
            mitumori_on_ex: this.sel_filter_val_ex[9], client_company_name_ex: this.sel_filter_val_ex[10],
            address_ex: this.sel_filter_val_ex[11],
            mitumori_sel_id: this.sel_id,
            all_check_sts: this.all_check_sts,
            all_check_flg: this.all_check_flg,
            sort_by: this.sort_by, sort_desc: this.sort_desc,
            current_page: this.current_page
          }
        })
        .then(response => {
          this.sel_data_list_field[9]["label"] = this.sel_on_type[this.on_type2]["text"]
          this.sel_data_list = response.data.mitumori_list;
          this.sel_data_count = response.data.mitumori_cnt;
          this.sel_date1 = response.data.date1;
          this.sel_date2 = response.data.date2;
          this.sel_filter_list[1] = response.data.jyutyu_status_filter_list;
          this.sel_filter_list[2] = response.data.nouhin_status_filter_list;
          this.sel_filter_list[3] = response.data.seikyu_status_filter_list;
          this.sel_filter_list[4] = response.data.nyukin_status_filter_list;
          if (this.all_check_flg == true) {
            this.all_check_flg = false;
            this.sel_id = response.data.sel_id;
          }
          this.on_submit = -1;
          this.mitumori_sel_load = false;
          this.isLoading = false;

        })

    },
    // 複数選択　モーダル表示
    selectModalShow: function () {
      this.current_page = 1
      this.sel_id = [];
      this.all_check_sts = false;
      this.mitumori_sel_load = true;
      setTimeout(() => {
        this.sel_date1 = this.date1;
        this.sel_date2 = this.date2;
        this.getSelectList();
        this.$root.$emit('bv::show::modal', 'modal-select');
        this.mitumori_sel_load = false;
      }, 100);
    },
    // 選択
    onSelect: function (index, id, m_code_ex, event) {
      if (event) {
        this.sel_id.push(id)
        this.sel_data_list[index].mitumori_sel = true;
      } else {
        var index2 = this.sel_id.indexOf(id)
        this.sel_id.splice(index2, 1);
        this.sel_data_list[index].mitumori_sel = false;
      }
    },
    // 全て選択、解除
    onAllCheck: function (event) {
      if (false) { //(this.sel_data_count > 100) {
        alert("まとめて選択は100件以内に絞ってから実行してください")
      } else {
        this.all_check_sts = event;
        this.all_check_flg = true;
        this.getSelectList();
      }

    },
    // ========================================
    // 処理選択
    selSubmit: function () {
      if (this.sel_id.length > 0) {
        if (this.on_submit >= 0) {
          switch (this.on_submit) {
            case 0:
              this.tikanShow(0);
              break;
            case 1:
              this.tikanShow(1);
              break;
            case 2:
              this.submitJyutyu();
              break;
            case 3:
              this.submitNouhin();
              break;
            case 4:
              this.submitSeikyu();
              break;
            case 5:
              this.submitNouhinSeikyu();
              break;
          }
        }
        this.all_check_sts = false;
        this.on_submit = -1
      } else {
        alert("見積を選択してください。");
        this.on_submit = -1
      }
    },
    // ========================================
    // 複製・置換表示
    tikanShow: function (type) {
      if (this.sel_id.length > 0) {
        this.tikan_chk = [false, false, false, false, false, false, false];
        this.tikan = ["", "", "", 0, "", "", ""];
        this.tikan_client_company_name = "";
        if (type == 0) {
          this.tikan_type = 0;
          this.tikan_title = "複製";
        } else {
          this.tikan_type = 1;
          this.tikan_title = "置換";
        }
        this.$root.$emit('bv::show::modal', 'modal-mitumori-tikan');
      } else {
        alert("見積を選択してください。")
      }
    },
    // ========================================
    // 複製
    submitCopy: function () {
      if (this.chkTikan()) {
        if (confirm('選択した見積を複製しますか？')) {
          axios
            .get("/mitumori/copy_mitumori", {
              params: {
                id: this.sel_id,
                tikan_chk: this.tikan_chk,
                tikan: this.tikan
              }
            })
            .then(response => {
              alert('複製しました');
              this.getSelectList();
              this.$root.$emit('bv::hide::modal', 'modal-mitumori-tikan')
            })
        }
      }
    },
    // 置換 顧客選択
    onTikanClientCompanySelect: function (item_id) {
      axios
        .get(`/mitumori/get_sel_client_person/${item_id}`)
        .then(response => {
          this.tikan[3] = response.data.client_company_id;
          this.tikan_client_company_name = response.data.client_company_name;
          this.$root.$emit('bv::hide::modal', 'select-client-company')
        })
    },
    // 置換検証
    chkTikan: function () {
      if ((this.tikan_chk[3] && this.tikan[3] == "") || (this.tikan_chk[4] && this.tikan[4] == "") ||
        (this.tikan_chk[5] && this.tikan[5] == "") || (this.tikan_chk[6] && this.tikan[6] == "")) {
        alert("顧客、見積日、納品日、請求日は空白では置換できません")
        return false
      } else {
        return true
      }
    },
    // 置換
    submitTikan: function () {
      if (this.chkTikan()) {
        var msg = "選択した見積を置換しますか？"
        if (this.tikan_chk[5] || this.tikan_chk[6]) {
          msg = msg + "\n納品日は受注済みの見積りのみ置換されます。\n請求日は納品済みの見積りのみ置換されます。"
        }
        if (confirm(msg)) {
          axios
            .get("/mitumori/tikan_mitumori", {
              params: {
                id: this.sel_id,
                tikan_chk: this.tikan_chk,
                tikan: this.tikan
              }
            })
            .then(response => {
              alert('置換しました');
              this.getSelectList();
              this.$root.$emit('bv::hide::modal', 'modal-mitumori-tikan')
            })
        }
      }
    },
    // ---------------------------------------
    // 受注確定
    submitJyutyu: function () {
      if (this.sel_id.length > 0) {
        if (confirm('選択した見積を「受注確定」にしますか？')) {
          axios
            .get("/mitumori/create_jyutyu", {
              params: {
                id: this.sel_id
              }
            })
            .then(response => {
              if (response.data.flg) {
                alert('受注を確定しました。');
                this.getSelectList();
              }
            })
        }
      } else {
        alert("見積を選択してください。")
      }
    },
    //---------------------------------------
    // 納品確定
    submitNouhin: function () {
      if (this.sel_id.length > 0) {
        if (confirm('選択した見積を「納品済み」にしますか？\n「納品可」の見積りのみ実行されます。')) {
          axios
            .get("/nouhin/comp_nouhin", {
              params: {
                id: this.sel_id
              }
            })
            .then(response => {
              if (response.data.flg) {
                alert('納品済みにしました');
                this.getSelectList();
              }
            })
        }
      } else {
        alert("見積を選択してください。")
      }
    },
    //---------------------------------------
    // 請求確定
    submitSeikyu: function () {
      if (this.sel_id.length > 0) {
        if (confirm('選択した見積を「請求済み」にしますか？\n「納品済み」の見積りのみ実行されます。')) {
          axios
            .get("/nouhin/comp_seikyu", {
              params: {
                id: this.sel_id
              }
            })
            .then(response => {
              if (response.data.flg) {
                alert('請求済みにしました');
                this.getSelectList();
              }
            })
        }
      } else {
        alert("見積を選択してください。")
      }
    },
    //---------------------------------------
    // 納品・請求確定
    submitNouhinSeikyu: function () {
      if (this.sel_id.length > 0) {
        if (confirm('選択した見積を「納品・請求済み」にしますか？\n納品済みは「納品可」の見積りのみ実行されます。\n請求済みは「納品済み」の見積りのみ実行されます。')) {
          axios
            .get("/nouhin/comp_nouhin", {
              params: {
                id: this.sel_id
              }
            })
            .then(response => {
              if (response.data.flg) {
                axios
                  .get("/nouhin/comp_seikyu", {
                    params: {
                      id: this.sel_id
                    }
                  })
                  .then(response => {
                    if (response.data.flg) {
                      alert('納品・請求済みにしました');
                      this.getSelectList();
                    }
                  })
              }
            })
        }
      } else {
        alert("見積を選択してください。")
      }

    },
    // 納品・請求書
    printNouhinSeikyu: function () {
      if (this.sel_id.length > 0) {
        this.print_loading = true;
        axios
          .get("/nouhin/print_nouhin_seikyu_mitumori", {
            params: {
              id: this.sel_id,
              nouhin_on_print_flg: this.nouhin_on_print_flg,
              seikyu_on_print_flg: this.seikyu_on_print_flg
            }
          })
          .then(response => {
            if (response.data.flg) {
              this.pdfView(response.data.url);
            } else {
              alert("受注済みの見積を選んでください")
            }
            this.print_loading = false;
          })
          .catch((error) => {
            console.log(error)
            this.print_loading = false;
          })
      } else {
        alert("見積を選択してください。")
      }
    },
    // =======================================================
    //
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    closeModal2: function () {
      this.getSelectList();
    },

    checkLogin2: function () {
      return axios
        .get(`/login/check_login`)
        .then(response => {
          if (!response.data.flg) {
            this.$router.push({ name: 'login', params: {} })
          } else {
            this.pms_hanbai = response.data.pms_hanbai;
            this.pms_nyukin = response.data.pms_nyukin;
            this.pms_zaiko = response.data.pms_zaiko;
            this.pms_hattyu = response.data.pms_hattyu;
            this.pms_master_company = response.data.pms_master_company;
            this.pms_master_hattyu_company = response.data.pms_master_hattyu_company;
            this.pms_master_client_company = response.data.pms_master_client_company;
            this.pms_master_item = response.data.pms_master_item;
          }
        })
    },
    // =======================================================
    // 受注表示
    jyutyuShow: function (mitumori_id) {
      this.$refs.child_jyutyu_detail2.getMitumori2Jyutyu(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'main-jyutyu_detail2');
      }, 100);
    },
    // 納品詳細
    nouhinShow: function (mitumori_id) {
      this.$refs.child_nouhin_detail2.getMitumori2Nouhin(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'main-nouhin_detail2');
      }, 100);
    },
    // 請求詳細
    seikyuShow: function (mitumori_id) {
      this.$refs.child_seikyu_detail2.getMitumori2Nouhin(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'main-seikyu_detail2');
      }, 100);
    },
    // 入金
    nyukinShow: function (mitumori_id) {
      this.$refs.child_nyukin_detail2.getMitumori2Nyukin(mitumori_id);
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'main-nyukin_detail2');
      }, 100);
    },
    // 複製・置換モーダルClose
    closeTikanModal: function () {
      this.on_submit = -1;
    },
    // ソート
    onSort: function (ctx) {
      this.sort_by = ctx.sortBy;
      this.sort_desc = ctx.sortDesc;
      this.getSelectList();
    }
  }

}
</script>

<style scoped></style>