<template>
  <div>
    <b-modal :id="modal_id" title="受注" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 受注 -->
      <form @submit.prevent="updateJyutyu">
        <div v-if="errors.length != 0">
          <ul v-for="e in errors" :key="e">
            <li>
              <font color="red">{{ e }}</font>
            </li>
          </ul>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>伝票番号</label>
          <b-input v-model="mitumori.m_code" type="text"
            :style="mitumori.cancel ? 'text-decoration: line-through; width: 200px;' : 'width: 200px;'" readonly />
          &nbsp;&nbsp;
          <label style="">受注日</label>
          <v-date-picker v-model="jyutyu.jyutyu_on" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>品題</label>
          <b-input v-model="mitumori.title" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
          <label>品類</label>
          <b-input v-model="mitumori.teiki_type1" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
          <label>品目</label>
          <b-input v-model="mitumori.teiki_type2" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <!-- 顧客選択 -->
          <SelectClientCompany @on_select='onClientCompanySelect' />
          <label>顧客</label>
          <span v-if="mitumori.readonly">
            <b-input v-model="mitumori.client_company_name" type="text" style="width:200px; " readonly />
            <b-button v-if="!client_loading" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
          </span>
          <span v-else>
            <b-input v-model="mitumori.client_company_name" type="text" style="width:200px; background-color: white;"
              @click="selectClientCompanyModalShow" readonly />
            <b-button v-if="!client_loading" @click="selectClientCompanyModalShow" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
            <b-button v-if="client_loading" variant="info" class="icon_btn" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </b-button>
          </span>
          &nbsp;&nbsp;
          <label>顧客担当者</label>
          <b-form-select v-model="mitumori.client_person_id" style="width: 200px" :disabled="mitumori.readonly">
            <option></option>
            <option v-for="option in sel_client_person" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          &nbsp;&nbsp;
          <label>担当者</label>
          <b-form-select v-model="mitumori.user_id" style="width: 200px" :disabled="mitumori.readonly">
            <option v-for="option in sel_user" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          <b-button type="submit" variant="primary" style="margin-left: 60px" :disabled="mitumori.cancel">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
      </form>
      <hr />
      商品
      <!-- 一覧 -->
      <div>
        <div style="width: 1090px; ">
          <b-table-filter :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :data_list_field="data_list_field" :data_list="data_list" @on_detail='modalShow' @on_delete='delJyutyuDetail'
            :filter_enable="false" />
        </div>
      </div>
        <div class="form-inline" style="margin: -15px 0px 0px 0px;">
          <span style="width:100px; ">{{ data_list.length }}件</span>
          <span class="form-inline" v-for="sum in total_by_tax" :key="sum.key" style="margin-left: 400px !important">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label>{{ sum.key }}%対象</label>
            <b-input v-bind:value="sum.total + ' (税 ' + sum.tax + ')'" type="text"
              style="width: 200px; text-align: right" readonly />
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label>合計</label>
          <b-input v-bind:value="sum_total + ' (税 ' + sum_tax + ')'" type="text" style="width: 200px; text-align: right"
            readonly />
        </div>

      <!-- モーダル -->
      <b-modal id="modal-jyutyu" title="商品" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label>商品</label>
            <b-input v-model="mitumori_detail.item_name" type="text" style="width:350px; background-color: white;"
              @click="selectItemModalShow" readonly />
            <b-button v-if="!item_loading" @click="selectItemModalShow" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
            <b-button v-if="item_loading" variant="info" class="icon_btn" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </b-button>
          </div>
          <div class="form-inline">
            <label>単価</label>
            <b-input v-model="mitumori_detail.tanka" type="number" />
          </div>
          <div class="form-inline">
            <label>数量</label>
            <b-input v-model="mitumori_detail.quantity" type="number" />
          </div>
          <div class="form-inline">
            <label>税率</label>
            <b-input v-model="mitumori_detail.tax" type="text" />
          </div>
        </div>
        <div class="modal-footer">
          <b-button @click="$bvModal.hide('modal-jyutyu')">
            <b-icon icon="x"></b-icon>取消
          </b-button>
          <b-button variant="primary" @click="createJyutyuDetail">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
        </div>
      </b-modal>
      <!-- 商品選択 -->
      <SelectItem @on_select='onSelect' />
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';

const jsonpAdapter = require('axios-jsonp');
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      // Header
      mitumori: [],
      jyutyu: [],
      sel_client_company: [],
      sel_client_person: [],
      sel_user: [],
      // table
      filter_list: new Array(15),
      filter_val: new Array(15),
      data_list: [],
      data_list_field: [
      { key: "item_code", label: "商品コード", thStyle: { width: "120px" }, sortable: true, filter: 'input' },
      { key: "type_name", label: "商品分類", thStyle: { width: "130px" }, sortable: true, filter: 'input' },
        { key: "item_name", label: "品名", thStyle: { width: "300px" }, sortable: true, filter: 'input' },
        { key: "quantity", label: "数量", thStyle: { width: "80px" }, tdClass: 'text-right', sortable: true, filter: 'input' },
        { key: "tanka_no_tax", label: "単価(税抜)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "tanka", label: "単価(税込)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "sum_row", label: "金額(税込)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "tax", label: "税率", thStyle: {}, tdClass: 'text-right', sortable: true, filter: 'select' },
      ],
      // detail
      mitumori_details: [],
      sum_total: 0,
      sum_tax: 0,
      total_by_tax: [],
      // modal
      mitumori_detail: [],
      sel_item_type: [],
      sel_item: [],
      //
      jyutyu_id: 0,
      //
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 420 + "px"
    }
  },
  mounted() {
  },
  filters: {
    customformat: function (value) {
      return value.toLocaleString();
    }
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    // 見積IDから受注ID取得
    getMitumori2Jyutyu: function (mitumori_id) {
      axios
        .get(`/mitumori/get_mitumori_2_jyutyu/${mitumori_id}`)
        .then(response => {
          this.jyutyu_id = response.data.jyutyu_id;
          this.getJyutyu();
        })
    },
    setJyutyuId: function (jyutyu_id) {
      this.jyutyu_id = jyutyu_id;
      this.getJyutyu();
    },
    // 受注 取得
    getJyutyu: function () {
      axios
        .get(`/jyutyu/get_jyutyu/${this.jyutyu_id}`)
        .then(response => {
          this.mitumori = response.data.mitumori_h;
          this.jyutyu = response.data.jyutyu_h;
          this.sel_client_company = response.data.sel_client_company;
          this.sel_client_person = response.data.sel_client_person;
          this.sel_user = response.data.sel_user;
          this.getJyutyuDetails();
        })
    },
    // 受注詳細 取得
    getJyutyuDetails: function () {
      axios
        .get(`/mitumori/get_mitumori_details/${this.mitumori.id}`)
        .then(response => {
          this.data_list = response.data.mitumori_details_h;
          this.sum_total = response.data.sum_total;
          this.sum_tax = response.data.sum_tax;
          this.total_by_tax = response.data.total_by_tax
        })
    },
    // 顧客会社_選択 → 顧客担当者_選択 更新
    selClientPersonChange: function () {
      axios
        .get(`/jyutyu/get_sel_client_person/${this.mitumori.client_company_id}`)
        .then(response => {
          this.sel_client_person = response.data.sel_client_person;
        })
    },
    // 受注 更新
    updateJyutyu: function () {
      axios
        .patch(`/mitumori/update_mitumori`, { mitumori: this.mitumori, jyutyu: this.jyutyu })
        .then(response => {
          (this.company = response.data);
          alert("保存しました。");
        })
    },
    // 商品 モーダル(新規、編集) 表示
    modalShow: function (mitumori_detail_id) {
      axios
        .get(`/mitumori/get_mitumori_detail/${mitumori_detail_id}`)
        .then(response => {
          this.mitumori_detail = response.data.mitumori_detail_h;
          this.sel_item_type = response.data.sel_item_type;
          this.sel_item = response.data.sel_item;
          this.$root.$emit('bv::show::modal', 'modal-jyutyu')
        })
    },
    // 商品分類_選択 → 商品 更新
    selItemTypeChange: function () {
      axios
        .get(`/mitumori/get_sel_item/${this.mitumori_detail.item_type_id}`)
        .then(response => {
          this.sel_item = response.data.sel_item;
        })
    },
    // 商品_選択 → 単価 更新
    selItemChange: function () {
      axios
        .get(`/mitumori/get_tanka/${this.mitumori_detail.item_id}`)
        .then(response => {
          this.mitumori_detail.tanka = response.data.tanka;
          this.mitumori_detail.quantity = response.data.quantity;
          this.mitumori_detail.tani = response.data.tani;
          this.mitumori_detail.tax = response.data.tax;
        })
    },
    // 受注詳細 新規
    createJyutyuDetail: function () {
      axios
        .patch(`/mitumori/create_mitumori_detail`, { mitumori: this.mitumori, mitumori_detail: this.mitumori_detail })
        .then(response => {
          (this.data_list = response.data.mitumori_details_h);
          this.getJyutyuDetails()
          this.$root.$emit('bv::hide::modal', 'modal-jyutyu')
        })
    },
    // 受注詳細 削除
    delJyutyuDetail: function (mitumori_detail_id) {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/mitumori/del_mitumori_detail/${mitumori_detail_id}`)
          .then(response => {
            this.getJyutyuDetails();
          })
      }
    },
    // =======================================================
    // 商品選択モーダル表示
    selectItemModalShow: function () {
      this.item_loading = true;
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'select-item')
        this.item_loading = false;
      }, 50);
    },
    // 商品選択
    onSelect: function (item_id) {
      axios
        .get(`/mitumori/get_tanka/${item_id}`)
        .then(response => {
          this.mitumori_detail.item_id = response.data.item.id;
          this.mitumori_detail.item_name = response.data.item.item_name;
          this.mitumori_detail.tanka = response.data.tanka;
          this.mitumori_detail.quantity = response.data.quantity;
          this.mitumori_detail.tax = response.data.tax;
          this.$root.$emit('bv::hide::modal', 'select-item')
        })
    },
    // =======================================================
    // 顧客選択モーダル表示
    selectClientCompanyModalShow: function () {
      this.client_loading = true;
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'select-client-company');
        this.client_loading = false;
      }, 50);
    },
    // 顧客選択
    onClientCompanySelect: function (item_id) {
      axios
        .get(`/mitumori/get_sel_client_person/${item_id}`)
        .then(response => {
          this.sel_client_person = response.data.sel_client_person;
          this.mitumori.client_company_id = response.data.client_company_id;
          this.mitumori.client_company_name = response.data.client_company_name;
          this.mitumori.client_person_id = "";
          this.$root.$emit('bv::hide::modal', 'select-client-company')
        })
    },
  }
}
</script>

<style scoped></style>