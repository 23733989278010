<template>
  <div>
    <b-modal :id="modal_id" title="納品" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <!-- 納品 -->
      <form @submit.prevent="updateNouhin">
        <div v-if="errors.length != 0">
          <ul v-for="e in errors" :key="e">
            <li>
              <font color="red">{{ e }}</font>
            </li>
          </ul>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>伝票番号</label>
          <b-form-select v-model="nouhin.id" @change="selMCodeEXChange"
            :style="mitumori.cancel ? 'text-decoration: line-through; width: 200px;' : 'width: 200px;'"
            :disabled="mitumori.readonly">
            <option v-for="option in sel_m_code_ex" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          &nbsp;&nbsp;
          <label style="">納品日</label>
          <v-date-picker v-model="nouhin.nouhin_on" class="date_picker">
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
              <b-button @click="nouhinOnDel" variant="danger" class="icon_btn_del">
                <b-icon icon="x" aria-hidden="true"></b-icon>
              </b-button>
            </template>
          </v-date-picker>
          &nbsp;&nbsp;
          <label style="">請求日</label>
          <b-input v-model="nouhin.seikyu_on_fmt" type="text" style="width: 160px" disabled />
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>品題</label>
          <b-input v-model="mitumori.title" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
          <label>品類</label>
          <b-input v-model="mitumori.teiki_type1" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
          <label>品目</label>
          <b-input v-model="mitumori.teiki_type2" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <!-- 顧客選択 -->
          <SelectClientCompany @on_select='onClientCompanySelect' />
          <label>顧客</label>
          <span v-if="mitumori.readonly">
            <b-input v-model="nouhin.client_company_name" type="text" style="width:200px;" readonly />
            <b-button v-if="!client_loading" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
          </span>
          <span v-else>
            <b-input v-model="nouhin.client_company_name" type="text" style="width:200px; background-color: white;"
              @click="selectClientCompanyModalShow" readonly />
            <b-button v-if="!client_loading" @click="selectClientCompanyModalShow" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
            <b-button v-if="client_loading" variant="info" class="icon_btn" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </b-button>
          </span>
          &nbsp;&nbsp;
          <label>敬称</label>
          <b-form-select v-model="nouhin.keisyou" style="width: 70px">
            <option v-for="option in sel_keisyou" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          &nbsp;&nbsp;
          <label>顧客担当者</label>
          <b-form-select v-model="nouhin.client_person_id" style="width: 200px" :disabled="mitumori.readonly">
            <option></option>
            <option v-for="option in sel_client_person" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          &nbsp;&nbsp;
          <label>担当者</label>
          <b-form-select v-model="nouhin.user_id" style="width: 200px" :disabled="mitumori.readonly">
            <option v-for="option in sel_person" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>備考</label>
          <b-form-textarea id="textarea" v-model="nouhin.note" rows="2" max-rows="2" style="width: 300px;">
          </b-form-textarea>
          &nbsp;
          <b-button type="submit" variant="primary" style="margin-left: 110px" :disabled="mitumori.cancel">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
          &nbsp;
          <b-button @click="printNouhinSeikyu(nouhin.id)" variant="success" style="width:150px !important">
            <b-icon icon="printer"></b-icon>&nbsp;納品・請求書
          </b-button>
          &nbsp;&nbsp;
          <b-form-checkbox id="checkbox-flg1" v-model="nouhin_on_print_flg" name="checkbox-flg1">
            納品日印刷
          </b-form-checkbox>
          <b-form-checkbox id="checkbox-flg2" v-model="seikyu_on_print_flg" name="checkbox-flg2">
            請求日印刷
          </b-form-checkbox>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <b-form-checkbox id="checkbox-1" v-model="nouhin.status" name="checkbox-1" @change="nouhin_submit"
            v-bind:disabled="nouhin.seikyu_status || mitumori.cancel">
            納品済み
          </b-form-checkbox>
        </div>
      </form>
      <hr />
      商品
      <!-- 一覧 -->
      <div>
        <div style="width: 1090px; ">
          <b-table-filter :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :data_list_field="data_list_field" :data_list="data_list" @on_edit='modalShow' :filter_enable="false"
            :edit_disabled="nouhin.status" />
        </div>
      </div>
      <div class="form-inline" style="margin: -15px 0px 0px 0px;">
        <span style="width:100px; ">{{ data_list.length }}件</span>
        <span class="form-inline" v-for="sum in total_by_tax" :key="sum.key" style="margin-left: 400px !important">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label>{{ sum.key }}%対象</label>
          <b-input v-bind:value="sum.total + ' (税 ' + sum.tax + ')'" type="text" style="width: 200px; text-align: right"
            readonly />
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <label>合計</label>
        <b-input v-bind:value="sum_total + ' (税 ' + sum_tax + ')'" type="text" style="width: 200px; text-align: right"
          readonly />
      </div>

      <!-- モーダル -->
      <b-modal id="modal-mitumori" title="商品" :header-bg-variant="'dark'" :header-text-variant="'light'"
        :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <label>伝票番号</label>
            <b-form-select v-model="mitumori_detail.nouhin_id" style="width: 200px">
              <option value="-1">
                {{ "新規" }}
              </option>
              <option v-for="option in sel_m_code_ex" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
          <div class="form-inline">
            <label>商品分類</label>
            <b-form-select v-model="mitumori_detail.item_type_id" @change="selItemTypeChange" style="width: 200px"
              :disabled="true">
              <option v-for="option in sel_item_type" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
          <div class="form-inline">
            <label>商品名</label>
            <b-form-select v-model="mitumori_detail.item_id" @change="selItemChange" style="width: 200px"
              :disabled="true">
              <option v-for="option in sel_item" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
          </div>
          <div class="form-inline">
            <label>単価</label>
            <b-input v-model="mitumori_detail.tanka" type="number" readonly />
          </div>
          <div class="form-inline">
            <label>数量</label>
            <b-input v-model="mitumori_detail.quantity" type="number" readonly />
          </div>
          <div class="form-inline">
            <label>税率</label>
            <b-input v-model="mitumori_detail.tax" type="text" readonly />
          </div>
          <div class="modal-footer">
            <b-button @click="$bvModal.hide('modal-mitumori')">
              <b-icon icon="x"></b-icon>取消
            </b-button>
            <b-button variant="primary" @click="updateNouhinDetail">
              <b-icon icon="save"></b-icon>&nbsp;保存
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ['modal_id'],
  components: {
  },
  data: function () {
    return {
      // Header
      mitumori: [],
      nouhin: [],
      sel_m_code_ex: [],
      sel_client_company: [],
      sel_client_person: [],
      sel_keisyou: [{ text: "", value: "" }, { text: "様", value: "様" }, { text: "殿", value: "殿" }, { text: "御中", value: "御中" }],
      sel_person: [],
      nouhin_on_print_flg: true,
      seikyu_on_print_flg: true,
      // table
      filter_list: new Array(15),
      filter_val: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "item_code", label: "商品コード", thStyle: { width: "120px" }, sortable: true, filter: 'input' },
        { key: "type_name", label: "商品分類", thStyle: { width: "130px" }, sortable: true, filter: 'input' },
        { key: "item_name", label: "品名", thStyle: { width: "300px" }, sortable: true, filter: 'input' },
        { key: "quantity", label: "数量", thStyle: { width: "80px" }, tdClass: 'text-right', sortable: true, filter: 'input' },
        { key: "tanka_no_tax", label: "単価(税抜)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "tanka", label: "単価(税込)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "sum_row", label: "金額(税込)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "tax", label: "税率", thStyle: {}, tdClass: 'text-right', sortable: true, filter: 'select' },
      ],
      // detail
      mitumori_details: [],
      sum_total: 0,
      sum_tax: 0,
      sel_tax_ex: [],
      total_by_tax: [],
      // modal
      mitumori_detail: [],
      sel_item_type: [],
      sel_item: [],
      //
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 520 + "px"
    }
  },
  mounted() {
  },
  filters: {
    customformat: function (value) {
      return value.toLocaleString();
    }
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    // 見積IDから納品ID取得
    getMitumori2Nouhin: function (mitumori_id) {
      axios
        .get(`/mitumori/get_mitumori_2_nouhin/${mitumori_id}`)
        .then(response => {
          this.nouhin_id = response.data.nouhin_id;
          this.getNouhin(response.data.nouhin_id);
          this.nouhin_on_print_flg = true;
          this.seikyu_on_print_flg = true;
        })
    },
    setNouhinId: function (nouhin_id) {
      this.nouhin_id = nouhin_id;
      this.getNouhin(this.nouhin_id);
    },
    // 納品 取得
    getNouhin: function (nouhin_id) {
      axios
        .get(`/nouhin/get_nouhin/${nouhin_id}`)
        .then(response => {
          this.mitumori = response.data.mitumori_h;
          this.nouhin = response.data.nouhin_h;
          this.sel_m_code_ex = response.data.sel_m_code_ex;
          this.sel_client_company = response.data.sel_client_company;
          this.sel_client_person = response.data.sel_client_person;
          this.sel_person = response.data.sel_person;
          this.getNouhinDetails();
        })
    },
    // 納品詳細 取得
    getNouhinDetails: function () {
      axios
        .get(`/nouhin/get_mitumori_details/${this.nouhin.id}`)
        .then(response => {
          this.data_list = response.data.mitumori_details_h;
          this.sum_total = response.data.sum_total;
          this.sum_tax = response.data.sum_tax;
          this.total_by_tax = response.data.total_by_tax
        })
    },
    // 伝票番号 選択
    selMCodeEXChange: function () {
      this.getNouhin(this.nouhin.id);
    },
    // 納品 更新
    updateNouhin: function () {
      axios
        .patch(`/nouhin/update_nouhin`, { nouhin: this.nouhin })
        .then(response => {
          if (response.data.flg) {
            alert("保存しました。");
          } else {
            alert(response.data.msg);
          }
          this.getNouhin(this.nouhin_id);
        })
    },
    // 納品済み
    nouhin_submit: function (checked) {
      if (checked) {
        if (this.nouhin.hattyu_status < 2) {
          alert('発注・検収が完了していません');
          this.nouhin.status = 0
        } else {
          if (confirm('納品済みにしますか？')) {
            axios
              .get(`/nouhin/comp_nouhin/${this.nouhin.id}`)
              .then(response => {
                alert('納品済みにしました。');
                this.getNouhin(this.nouhin_id);
              })
          } else {
            this.nouhin.status = false
          }
        }
      } else {
        if (confirm('納品済みを取り消しますか？')) {
          axios
            .get(`/nouhin/comp_nouhin_cancel/${this.nouhin.id}`)
            .then(response => {
              alert('納品済みを取り消しました');
            })
        } else {
          this.nouhin.status = true
        }
      }
    },
    // 売上 印刷
    printUriage: function (nouhin_id) {
      axios
        .get(`/nouhin/print_uriage/${nouhin_id}`)
        .then(response => {
          this.pdfView(response.data.url);
        })
        .catch((error) => {
          console.log(error)
        })

    },
    // 納品 印刷
    printNouhin: function (nouhin_id) {
      axios
        .get(`/nouhin/print_nouhin/${nouhin_id}`)
        .then(response => {
          this.pdfView(response.data.url);
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 請求 印刷
    printSeikyu: function (nouhin_id) {
      axios
        .get(`/nouhin/print_seikyu/${nouhin_id}`)
        .then(response => {
          this.pdfView(response.data.url);
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 売上・納品・請求書 印刷
    printNouhinSeikyu: function (nouhin_id) {
      axios
        .get("/nouhin/print_nouhin_seikyu/", {
          params: {
            id: [nouhin_id],
            nouhin_on_print_flg: this.nouhin_on_print_flg,
            seikyu_on_print_flg: this.seikyu_on_print_flg
          }
        })
        .then(response => {
          this.pdfView(response.data.url);
        })
        .catch((error) => {
          console.log(error)
        })
    },

    // 商品 モーダル(新規、編集) 表示
    modalShow: function (mitumori_detail_id) {
      axios
        .get(`/mitumori/get_mitumori_detail/${mitumori_detail_id}`)
        .then(response => {
          this.mitumori_detail = response.data.mitumori_detail_h;
          this.sel_item_type = response.data.sel_item_type;
          this.sel_item = response.data.sel_item;
          this.$root.$emit('bv::show::modal', 'modal-mitumori')
        })
    },
    // 商品分類_選択 → 商品 更新
    selItemTypeChange: function () {
      axios
        .get(`/mitumori/get_sel_item/${this.mitumori_detail.item_type_id}`)
        .then(response => {
          this.sel_item = response.data.sel_item;
        })
    },
    // 商品_選択 → 単価 更新
    selItemChange: function () {
      axios
        .get(`/mitumori/get_tanka/${this.mitumori_detail.item_id}`)
        .then(response => {
          this.mitumori_detail.tanka = response.data.tanka;
          this.mitumori_detail.quantity = response.data.quantity;
          this.mitumori_detail.tani = response.data.tani;
          this.mitumori_detail.tax = response.data.tax;
        })
    },
    // 受注詳細 編集
    updateNouhinDetail: function () {
      axios
        .patch(`/nouhin/update_mitumori_detail`, { mitumori: this.mitumori, mitumori_detail: this.mitumori_detail })
        .then(response => {
          (this.data_list = response.data.mitumori_details_h);
          this.getNouhinDetails();
          this.$root.$emit('bv::hide::modal', 'modal-mitumori');
          this.getNouhin(this.nouhin.id);
        })
    },
    // =======================================================
    // 顧客選択モーダル表示
    selectClientCompanyModalShow: function () {
      this.client_loading = true;
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'select-client-company');
        this.client_loading = false;
      }, 50);
    },
    // 顧客選択
    onClientCompanySelect: function (item_id) {
      axios
        .get(`/mitumori/get_sel_client_person/${item_id}`)
        .then(response => {
          this.sel_client_person = response.data.sel_client_person;
          this.nouhin.client_company_id = response.data.client_company_id;
          this.nouhin.client_company_name = response.data.client_company_name;
          this.nouhin.client_person_id = "";
          this.$root.$emit('bv::hide::modal', 'select-client-company')
        })
    },
    // 納品日 削除
    nouhinOnDel: function () {
      this.nouhin.nouhin_on = null;
    }
  }
}
</script>

<style scoped></style>