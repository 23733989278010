<template>
  <div>
    <b-modal id="modal-mitumori_detail" title="見積" size="xl" :header-bg-variant="'dark'" :header-text-variant="'light'"
      :body-bg-variant="'light'" @hide="closeModal" hide-footer centered scrollable>
      <form @submit.prevent="updateMitumori" class="form-group main_modal_div">
        <div v-if="errors.length != 0">
          <ul v-for="e in errors" :key="e">
            <li>
              <font color="red">{{ e }}</font>
            </li>
          </ul>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>伝票番号</label>
          <b-input v-model="mitumori.m_code" type="text"
            :style="mitumori.cancel ? 'text-decoration: line-through; width: 200px;' : 'width: 200px;'" readonly />
          &nbsp;&nbsp;
          <label style="">見積日</label>
          <v-date-picker v-model="mitumori.mitumori_on" class="date_picker" is-required>
            <template v-slot="{ inputValue, inputEvents }">
              <b-input :value="inputValue" v-on="inputEvents" readonly />
            </template>
          </v-date-picker>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>品題</label>
          <b-input v-model="mitumori.title" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
          <label>品類</label>
          <b-input v-model="mitumori.teiki_type1" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
          <label>品目</label>
          <b-input v-model="mitumori.teiki_type2" type="text" style="width:200px;" :readonly="mitumori.readonly" />
          &nbsp;&nbsp;
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <!-- 顧客選択 -->
          <SelectClientCompany ref="select_client_company_ref" @on_select='onClientCompanySelect' />
          <label>顧客</label>
          <span v-if="mitumori.readonly">
            <b-input v-model="mitumori.client_company_name" type="text" style="width:200px; " readonly />
            <b-button v-if="!client_loading" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
          </span>
          <span v-else>
            <b-input v-model="mitumori.client_company_name" type="text" style="width:200px; background-color: white;"
              @click="selectClientCompanyModalShow" readonly />
            <b-button v-if="!client_loading" @click="selectClientCompanyModalShow" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
            <b-button v-if="client_loading" variant="info" class="icon_btn" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </b-button>
          </span>
          &nbsp;&nbsp;
          <label>敬称</label>
          <b-form-select v-model="mitumori.keisyou" style="width: 70px">
            <option v-for="option in sel_keisyou" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          &nbsp;&nbsp;
          <label>顧客担当者</label>
          <b-form-select v-model="mitumori.client_person_id" style="width: 200px" :disabled="mitumori.readonly">
            <option></option>
            <option v-for="option in sel_client_person" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
          &nbsp;&nbsp;
          <label>担当者</label>
          <b-form-select v-model="mitumori.user_id" style="width: 200px" :disabled="mitumori.readonly">
            <option v-for="option in sel_person" v-bind:value="option.value" :key="option.id">
              {{ option.text }}
            </option>
          </b-form-select>
        </div>
        <div class="form-inline" style="margin: 10px 0px">
          <label>備考</label>
          <b-form-textarea id="textarea" v-model="mitumori.note" rows="2" max-rows="2" style="width: 300px;">
          </b-form-textarea>
          &nbsp;
          <b-button type="submit" variant="primary" style="margin-left: 300px" :disabled="mitumori.cancel">
            <b-icon icon="save"></b-icon>&nbsp;保存
          </b-button>
          &nbsp;
          <b-button @click="copyMitumori" variant="info">
            <b-icon icon="files"></b-icon>&nbsp;複製
          </b-button>
          &nbsp;
          <b-button @click="printMitumori(mitumori_id)" variant="success">
            <b-icon icon="printer"></b-icon>&nbsp;見積書
          </b-button>
          &nbsp;&nbsp;
          <b-form-checkbox id="checkbox-1" v-model="mitumori.status" name="checkbox-1" @change="jyutyu_submit"
            v-bind:disabled="!mitumori.jyutyu_submit_enabled">
            受注確定
          </b-form-checkbox>
          <b-form-checkbox id="checkbox-c" v-model="mitumori.cancel" name="checkbox-c" @change="delMitumori"
            v-bind:disabled="!mitumori.cancel_enabled">
            取り消し
          </b-form-checkbox>
        </div>
      </form>
      <hr />
      商品
      <!-- 一覧 -->
      <div>
        <div style="width: 1160px; ">
          <b-table-filter-drag :maxHeight="maxHeight" :filter_list="filter_list" :filter_val="filter_val"
            :data_list_field="data_list_field" :data_list="data_list" @on_edit='modalShow'
            @on_drag_comp='onDragComp' :filter_enable="false" :edit_disabled="mitumori.readonly" />
        </div>
      </div>
      <SelectItem @on_select='onItemSelect' />
      <div style="margin: -10px 0px 0px 0px">
        <!-- <b-button @click="modalShow(-1)" variant="info" class="icon_btn_w" v-bind:disabled="mitumori.readonly">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
        </b-button> -->
        <b-button v-if="!item_loading2" @click="selectItemModalShow(-1)" variant="info" class="icon_btn_w"
          v-bind:disabled="mitumori.readonly">
          <b-icon icon="plus" aria-hidden="true"></b-icon>
        </b-button>
        <b-button v-if="item_loading2" variant="info" class="icon_btn_w" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </b-button>
      </div>

      <div class="form-inline" style="width: 1150px">
        <div class="form-inline" style="margin: 0px 0px 0px auto;">
          <span class="form-inline" v-for="sum in total_by_tax" :key="sum.key" style="margin: 0px !important">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label>{{ sum.key }}%対象</label>
            <b-input v-bind:value="sum.total + ' (税 ' + sum.tax + ')'" type="text"
              style="width: 200px; text-align: right" readonly />
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label>合計</label>
          <b-input v-bind:value="sum_total + ' (税 ' + sum_tax + ')'" type="text" style="width: 200px; text-align: right"
            readonly />
        </div>
      </div>

      <!-- モーダル -->
      <b-modal id="modal-mitumori_item" :title="mitumori_item_title" :header-bg-variant="'dark'"
        :header-text-variant="'light'" :body-bg-variant="'light'" hide-footer centered>
        <div class="modal_div">
          <div class="form-inline">
            <!-- 商品選択 -->
            <!-- <SelectItem @on_select='onItemSelect' /> -->
            <label v-b-tooltip.hover.bottom.v-info="tooltip_item">
              商品&nbsp;<b-icon icon="info-circle-fill" scale="1" variant="info"></b-icon>
            </label>
            <b-input v-model="mitumori_detail.master_item_name" type="text" style="width:300px; "
              @click="selectItemModalShow" readonly />
            <b-button v-if="!item_loading" @click="selectItemModalShow" variant="info" class="icon_btn">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
            <b-button v-if="item_loading" variant="info" class="icon_btn" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </b-button>
          </div>
          <div class="form-inline">
            <label>品名</label>
            <b-input v-model="mitumori_detail.item_name" type="text" style="width:300px;" />
          </div>
          <div class="form-inline">
            <label>数量</label>
            <b-input v-model="mitumori_detail.quantity" type="number" min="0" />
          </div>
          <div class="form-inline">
            <label>単価</label>
            <b-input v-model="mitumori_detail.tanka" type="number" />
          </div>
          <div class="form-inline">
            <label>税率</label>
            <b-form-select v-model="mitumori_detail.tax" style="width: 200px">
              <option v-for="option in sel_tax_ex" v-bind:value="option.value" :key="option.id">
                {{ option.text }}
              </option>
            </b-form-select>
            <!-- <b-input v-model="mitumori_detail.tax" type="number" /> -->
          </div>
          <div class="modal-footer">
            <b-button @click="$bvModal.hide('modal-mitumori_item')">
              <b-icon icon="x"></b-icon>取消
            </b-button>
            <b-button @click="delMitumoriDetail" variant="danger" class="" :disabled="this.mitumori_detail.id == null">
              <b-icon icon="save"></b-icon>&nbsp;削除
            </b-button>
            <b-button variant="primary" @click="createMitumoriDetail">
              <b-icon icon="save"></b-icon>&nbsp;保存
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import VCalendar from 'v-calendar'
Vue.use(VCalendar)

const jsonpAdapter = require('axios-jsonp');
export default {
  components: {
  },
  data: function () {
    return {
      mitumori_id: 0,
      // header
      mitumori: [],
      mitumori_detail: [],
      sel_client_company: [],
      sel_client_person: [],
      sel_person: [],
      sel_keisyou: [{ text: "", value: "" }, { text: "様", value: "様" }, { text: "殿", value: "殿" }, { text: "御中", value: "御中" }],
      // table
      filter_list: new Array(15),
      filter_val: new Array(15),
      data_list: [],
      data_list_field: [
        { key: "edit", label: "編集", thStyle: { width: "60px !important", textAlign: "center" }, tdClass: 'text-center', sortable: false, filter: false, edit: true },
        { key: "item_code", label: "商品コード", thStyle: { width: "110px" }, sortable: true, filter: 'input' },
        { key: "type_name", label: "商品分類", thStyle: { width: "130px" }, sortable: true, filter: 'input' },
        { key: "item_name", label: "品名", thStyle: { width: "300px" }, sortable: true, filter: 'input' },
        { key: "quantity", label: "数量", thStyle: { width: "80px"}, tdClass: 'text-right', sortable: true, filter: 'input' },
        { key: "tanka_no_tax", label: "単価(税抜)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "tanka", label: "単価(税込)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "sum_row", label: "金額(税込)", thStyle: { width: "120px" }, tdClass: 'text-right', sortable: true, filter: 'select' },
        { key: "tax", label: "税率", thStyle: {width: "120px"}, tdClass: 'text-right', sortable: true, filter: 'select' },
      ],
      // detail
      sum_total: 0,
      sum_tax: 0,
      sel_tax_ex: [],
      total_by_tax: [],
      // modal
      mitumori_detail_id: 0,
      mitumori_item_title: "商品 > 編集",
      item_loading2: false,
      sel_item_type: [],
      sel_item: [],
      tooltip_item: '　',
      msg: 'msg',
      showModal: false,
      deleteTarget: -1,
      errors: [],
      maxHeight: document.documentElement.scrollHeight - 530 + "px"
    }
  },
  mounted() {
  },
  filters: {
    customformat: function (value) {
      return value.toLocaleString();
    }
  },
  methods: {
    closeModal: function (id) {
      this.$emit('close_modal', id);
    },
    // 見積ID 
    setMitumori: function (mitumori_id) {
      this.mitumori_id = mitumori_id;
      this.getMitumori();
      this.getMitumoriDetails();
    },
    // 見積 取得
    getMitumori: function () {
      axios
        .get(`/mitumori/get_mitumori/${this.mitumori_id}`)
        .then(response => {
          this.mitumori = response.data.mitumori_h;
          this.sel_client_company = response.data.sel_client_company;
          this.sel_client_person = response.data.sel_client_person;
          this.sel_person = response.data.sel_person;
        })
    },
    // 見積詳細 取得
    getMitumoriDetails: function () {
      axios
        .get(`/mitumori/get_mitumori_details/${this.mitumori_id}`)
        .then(response => {
          this.data_list = response.data.mitumori_details_h;
          this.sum_total = response.data.sum_total;
          this.sum_tax = response.data.sum_tax;
          this.total_by_tax = response.data.total_by_tax
        })
    },
    // 受注 更新
    updateMitumori: function () {
      axios
        .patch(`/mitumori/update_mitumori`, { mitumori: this.mitumori })
        .then(response => {
          alert("保存しました。");
          this.getMitumori();
        })
    },
    // 受注 削除
    delMitumori: function (checked) {
      if (checked) {
        if (confirm('取り消しますか？取り消し後は内容の変更はできなくなります。')) {
          axios
            .delete(`/mitumori/del_mitumori/${this.mitumori_id}`)
            .then(response => {
              if (response.data.flg) {
                this.getMitumori();
                alert("取り消しました");
              } else {
                alert("納品済みの見積は取り消しできません")
              }
            })
        } else {
          this.mitumori.cancel = false;
        }
      } else {
        if (confirm('取り消しをキャンセルしますか？')) {
          axios
            .get(`/mitumori/cancel_cancel/${this.mitumori_id}`)
            .then(response => {
              if (response.data.flg) {
                alert('取り消しをキャンセルしました。');
                this.getMitumori();
              }
            })
        } else {
          this.mitumori.cancel = true;
        }
      }
    },
    // 受注確定
    jyutyu_submit: function (checked) {
      if (checked) {
        if (confirm('受注を確定しますか？確定後は内容の変更はできなくなります。')) {
          axios
            .get(`/mitumori/create_jyutyu/${this.mitumori_id}`)
            .then(response => {
              if (response.data.flg) {
                alert('受注を確定しました。');
                this.getMitumori();
              }
            })
        } else {
          this.mitumori.status = false
        }
      } else {
        if (confirm('受注を確定を取り消しますか？')) {
          axios
            .get(`/mitumori/create_jyutyu_cancel/${this.mitumori_id}`)
            .then(response => {
              if (response.data.flg) {
                alert('受注の確定を取り消しました。');
                this.getMitumori();
              }
            })
        } else {
          this.mitumori.status = true
        }
      }
    },
    // 複製
    copyMitumori: function () {
      if (confirm('複製しますか？')) {
        axios
          .get(`/mitumori/copy_mitumori/${this.mitumori_id}`)
          .then(response => {
            alert('複製しました。複製先を表示します。');
            this.setMitumori(response.data.mitumori_id);
          })
      }
    },
    // 印刷
    printMitumori: function (mitumori_id) {
      // this.new_window();
      axios
        .get(`/mitumori/print_mitumori/${mitumori_id}`)
        .then(response => {
          this.pdfView(response.data.url);
        })
        .catch((error) => {
          console.log(error)
        })

    },
    // 商品 モーダル(新規、編集) 表示
    modalShow: function (mitumori_detail_id) {
      axios
        .get(`/mitumori/get_mitumori_detail/${mitumori_detail_id}`)
        .then(response => {
          this.mitumori_detail_id = mitumori_detail_id
          if (this.mitumori_detail_id == -1) {
            this.mitumori_item_title = "商品 > 追加"
          } else {
            this.mitumori_item_title = "商品 > 編集"
            this.mitumori_detail = response.data.mitumori_detail_h;
          }
          this.sel_tax_ex = response.data.sel_tax_ex;
          this.$root.$emit('bv::show::modal', 'modal-mitumori_item');
          this.set_tooltip_item();

        })
    },
    // ドラッグで並び替え
    onDragComp: function (data_list) {
      axios
        .patch(`/mitumori/chg_num`, { mitumori_detail_liist: data_list })
        .then(response => {
          if (!response.data.flg) {
            alert(response.data.msg);
          }
        })
    },  
    // 見積詳細 新規
    createMitumoriDetail: function () {
      if (this.processing) return;
      this.processing = true
      axios
        .patch(`/mitumori/create_mitumori_detail`, { mitumori: this.mitumori, mitumori_detail: this.mitumori_detail })
        .then(response => {
          this.processing = false
          if (response.data.flg) {
            this.getMitumoriDetails()
            this.$root.$emit('bv::hide::modal', 'modal-mitumori_item')
          } else {
            alert(response.data.msg);
          }
        })
    },
    // 見積詳細 削除
    delMitumoriDetail: function () {
      if (confirm('削除しますか？')) {
        axios
          .delete(`/mitumori/del_mitumori_detail/${this.mitumori_detail.id}`)
          .then(response => {
            alert('削除しました')
            this.$root.$emit('bv::hide::modal', 'modal-mitumori_item')
            this.getMitumoriDetails();
          })
      }
    },
    // =======================================================
    // 商品選択モーダル表示
    selectItemModalShow: function (flg) {
      if (flg == -1) {
        this.mitumori_detail_id = -1;
        this.item_loading2 = true;
      } else {
        this.item_loading = true;
      }
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'select-item')
        if (flg == -1) {
          this.item_loading2 = false;
        } else {
          this.item_loading = false;
        }
      }, 50);
    },

    // 商品選択
    onItemSelect: function (item_id) {
      if (this.mitumori_detail_id == -1) {
        this.modalShow(-1);
      }
      axios
        .get(`/mitumori/get_tanka/${item_id}`)
        .then(response => {
          axios
            .get(`/mitumori/get_mitumori_detail/${this.mitumori_detail_id}`)
            .then(response2 => {
              this.mitumori_detail = response2.data.mitumori_detail_h;
              this.mitumori_detail.item_id = response.data.item.id;
              this.mitumori_detail.master_item_name = response.data.item.item_name;
              this.mitumori_detail.item_name = response.data.item.item_name;
              this.mitumori_detail.item_type_id = response.data.item.item_type_id;
              this.mitumori_detail.item_code = response.data.item.item_code;
              this.mitumori_detail.hattyu_company = response.data.hattyu_company
              this.mitumori_detail.master_hattyu_tanka = response.data.item.hattyu_tanka;
              this.mitumori_detail.master_tanka = response.data.tanka;
              this.mitumori_detail.tanka = response.data.tanka;
              this.mitumori_detail.quantity = response.data.quantity;
              this.mitumori_detail.tax = response.data.tax;
              this.$root.$emit('bv::hide::modal', 'select-item');
              this.set_tooltip_item();
            })
        })

    },
    // =======================================================
    // 商品tooltip
    set_tooltip_item: function () {
      var tool_tip = "<font color='#f5f5f5'>商品コード: </font>" + this.mitumori_detail.item_code + "<br/>"
      tool_tip = tool_tip + "<font color='#f5f5f5'>発注先: </font>" + this.mitumori_detail.hattyu_company + "<br/>"
      tool_tip = tool_tip + "<font color='#f5f5f5'>仕入れ単価: </font>" + this.mitumori_detail.master_hattyu_tanka + "<br/>"
      tool_tip = tool_tip + "<font color='#f5f5f5'>単価: </font>" + this.mitumori_detail.master_tanka
      this.tooltip_item = { html: true, title: tool_tip }
    },
    // =======================================================
    // 顧客選択モーダル表示
    selectClientCompanyModalShow: function () {
      this.client_loading = true;
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'select-client-company');
        this.client_loading = false;
      }, 50);
    },
    // 顧客選択
    onClientCompanySelect: function (item_id) {
      axios
        .get(`/mitumori/get_sel_client_person/${item_id}`)
        .then(response => {
          this.sel_client_person = response.data.sel_client_person;
          this.mitumori.client_company_id = response.data.client_company_id;
          this.mitumori.client_company_name = response.data.client_company_name;
          this.mitumori.client_person_id = "";
          this.$root.$emit('bv::hide::modal', 'select-client-company')
        })
    },
  }
}
</script>

<style scoped></style>